<template>
<b-card no-body class="border">
  <template #header>
    <h5 class="card-title">MTWI at a Glance</h5>
  </template>
  <b-card-body class="border-bottom">
    <div class="d-flex align-items-center">
      <b-form-checkbox value="Y" unchecked-value="N" v-model="setting.onOff" @input.native="doSwicth($event)">Show Section?</b-form-checkbox>
      <div class="ml-auto">
        <b-button v-if="openFormStatic" @click="openFormStatic=false" variant="light" class="btn-rounded">
          Cancel <i class="fas fa-times ml-2"></i>
        </b-button>
        <b-button v-else variant="outline-info" class="btn-rounded" @click="doOpenForm()">
          Update Content <i class="fas fa-pencil-alt ml-2"></i>
        </b-button>
      </div>
    </div>
  </b-card-body>

  <template v-if="openFormStatic">
    <validation-observer
      v-slot="{ handleSubmit }" ref="VFormAboutSingkat"
    >
      <b-form @submit.prevent="handleSubmit(doSubmitContent(false))">
        <b-card-body>
          <div class="row">
            <div class="col-md-6">
              <b-form-group>
                <template #label>
                  Title (ID) <strong class="text-danger">*</strong>
                </template>
                <b-form-input @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" v-model="input.title_id" />
                <VValidate 
                  name="Title ID" 
                  v-model="input.title_id" 
                  :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
                />
              </b-form-group>
              <b-form-group>
                <template #label>
                  MTWI at a glance (ID) <strong class="text-danger">*</strong>
                </template>
                <CKEditor 
                  id="aboutSingkatID"
                  :value.sync="input.desc_id"
                  :customToolbar="parent.Config.toolsBasicCK"
                />
                <VValidate 
                  name="Content ID" 
                  v-model="input.desc_id" 
                  :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
                />
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group>
                <template #label>
                  Title (EN) <strong class="text-danger">*</strong>
                </template>
                <b-form-input @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" v-model="input.title_en" />
                <VValidate 
                  name="Title EN" 
                  v-model="input.title_en" 
                  :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
                />
              </b-form-group>
              <b-form-group>
                <template #label>
                  MTWI at a glance (EN) <strong class="text-danger">*</strong>
                </template>
                <CKEditor 
                  id="aboutSingkatEN"
                  :value.sync="input.desc_en"
                  :customToolbar="parent.Config.toolsBasicCK"
                />
                <VValidate 
                  name="Content EN" 
                  v-model="input.desc_en" 
                  :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
                />
              </b-form-group>
            </div>
          </div>
        </b-card-body>
        <b-card-footer>
          <div class="text-right">
            <button type="submit" class="btn btn-info btn-rounded">Save Changes</button>
          </div>
        </b-card-footer>
      </b-form>
    </validation-observer>
  </template>
  <template v-else>
    <b-card-body>
      <div class="row">
        <div class="col-md-6">
          <h4><b-badge variant="info">ID</b-badge></h4>
          <h3 v-if="setting.title_id" class="card-title mb-2">{{ setting.title_id }}</h3>
          <div v-if="setting.desc_id" v-html="setting.desc_id"></div>
          <p v-else><i>No content yet.</i></p>
        </div>
        <div class="col-md-6">
          <h4><b-badge variant="info">EN</b-badge></h4>
          <h3 v-if="setting.title_en" class="card-title mb-2">{{ setting.title_en }}</h3>
          <div v-if="setting.desc_en" v-html="setting.desc_en"></div>
          <p v-else><i>No content yet.</i></p>
        </div>
      </div>
    </b-card-body>
  </template>

</b-card>
</template>

<script>
import Gen from '@/libs/Gen'
import CKEditor from '@/components/CKEditor'

export default{
  components:{ CKEditor },
  props:{ setting:Object },
  data(){
    return {
      input:{},
      openFormStatic:false
    }
  },
  computed:{
    parent(){
      return this.$parent.$parent.$parent.$parent.$parent.$parent.$parent
    },
  },
  methods:{
    doOpenForm(){
      this.input = JSON.parse(JSON.stringify(this.setting))
      this.openFormStatic = true
    },
    doSubmitContent(){
      this.$refs["VFormAboutSingkat"].validate().then(success => {
        if (!success) return
        let input = this.input
        input.needSubmit=false
        
        this.parent.loadingOverlay = true 
        const data = JSON.parse(JSON.stringify(input))

        Gen.apiRest(
          "/do/"+this.parent.modulePage, 
          {data: Object.assign({}, data, {
            type:'update-static-content', 
            id:input.id
          })}, 
          "POST"
        ).then(res=>{
            this.parent.loadingOverlay = false
            let resp = res.data
            
            if(Object.keys(resp.data.rowAlgoliaID).length){
              this.parent.submitStaicContentAlgolia('inv-company-info-about-singkat',resp.data.rowAlgoliaID,'id')
            }
            if(Object.keys(resp.data.rowAlgoliaEN).length){
              this.parent.submitStaicContentAlgolia('inv-company-info-about-singkat',resp.data.rowAlgoliaEN,'en')
            }
            
            this.$emit('update:setting', input)
            this.openFormStatic=false

            resp.statusType = 200
            this.parent.doSetAlertForm(resp)
        }).catch(err=>{
            this.parent.loadingOverlay = false
            
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.message = err.response?.data?.message
                err.messageError = err.message
            }

            this.parent.doSetAlertForm(err)
        })
      })
    },
    doSwicth(e){
      const changeStatus = e.target.checked?'Y':'N'
      this.$swal({
        title: `${changeStatus=='N' ? 'Hide this section' : 'Show this section'}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: changeStatus=="N"?'#f7ab38':'#30d64c',
        confirmButtonText:  `Yes, ${changeStatus=='N'? 'hide' : 'show'} it!`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.parent.loadingOverlay=true
          Gen.apiRest(
            "/do/"+this.parent.modulePage, 
            {data: Object.assign({}, {
              type:'update-onoff-static-content', 
              slug:this.setting.slug,
              status: changeStatus
            })}, 
            "POST"
          )
            .then(res=>{
              this.parent.loadingOverlay = false 
              this.setting.onOff = changeStatus
              let resp = res.data
              
              if(Object.keys(resp.data.rowAlgoliaID).length){
                this.parent.submitStaicContentAlgolia('inv-company-info-about-singkat',resp.data.rowAlgoliaID,'id')
              }
              if(Object.keys(resp.data.rowAlgoliaEN).length){
                this.parent.submitStaicContentAlgolia('inv-company-info-about-singkat',resp.data.rowAlgoliaEN,'en')
              }

              this.$swal({
                title: `Status changed to ${changeStatus=='Y'? 'Show' : 'Hide'}.`,
                icon: 'success',
              })
            })
        }else{
          this.setting.onOff = changeStatus=='Y'?'N':'Y'
        }
      })
    }
  },
}
</script>