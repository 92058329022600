<template>
<b-card no-body class="border">
  <template #header>
    <h5 class="card-title">Company Vision and Values</h5>
  </template>
  <b-card-body class="border-bottom">
    <div class="d-flex align-items-center">
      <b-form-checkbox value="Y" unchecked-value="N" v-model="setting.onOff" @input.native="doSwicth($event)">Show Section?</b-form-checkbox>
      <div class="ml-auto">
        <b-button v-if="openFormStatic" @click="openFormStatic=false" variant="light" class="btn-rounded">
          Cancel <i class="fas fa-times ml-2"></i>
        </b-button>
        <b-button v-else variant="outline-info" class="btn-rounded" @click="doOpenForm()">
          Update Content <i class="fas fa-pencil-alt ml-2"></i>
        </b-button>
      </div>
    </div>
  </b-card-body>

  <template v-if="openFormStatic">
    <validation-observer
      v-slot="{ handleSubmit }" ref="VFormVisi"
    >
      <b-form @submit.prevent="handleSubmit(doSubmitContent(false))">
        <b-card-body>
          <div class="row">
            <div class="col-md-6">
              <b-form-group>
                <template #label>
                  Title (ID) <strong class="text-danger">*</strong>
                </template>
                <b-form-input @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" v-model="input.title_id" />
                <VValidate 
                  name="Title ID" 
                  v-model="input.title_id" 
                  :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
                />
              </b-form-group>
              <b-form-group>
                <template #label>
                  Content (ID) <strong class="text-danger">*</strong>
                </template>
                <CKEditor 
                  id="companyVisionID"
                  :value.sync="input.desc_id"
                  :customToolbar="parent.Config.toolsBasicCK"
                />
                <VValidate 
                  name="Content ID" 
                  v-model="input.desc_id" 
                  :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
                />
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group>
                <template #label>
                  Title (EN) <strong class="text-danger">*</strong>
                </template>
                <b-form-input @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" v-model="input.title_en" />
                <VValidate 
                  name="Title EN" 
                  v-model="input.title_en" 
                  :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
                />
              </b-form-group>
              <b-form-group>
                <template #label>
                  Content (EN) <strong class="text-danger">*</strong>
                </template>
                <CKEditor 
                  id="companyVisionEN"
                  :value.sync="input.desc_en"
                  :customToolbar="parent.Config.toolsBasicCK"
                />
                <VValidate 
                  name="Content EN" 
                  v-model="input.desc_en" 
                  :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
                />
              </b-form-group>
            </div>
          </div>
        </b-card-body>
        <b-card-footer>
          <div class="text-right">
            <button type="submit" class="btn btn-info btn-rounded">Save Changes</button>
          </div>
        </b-card-footer>
      </b-form>
    </validation-observer>
  </template>
  <b-card-body class="border-bottom" v-else>
    <div class="row">
      <div class="col-md-6">
        <h4><b-badge variant="info">ID</b-badge></h4>
        <h3 v-if="setting.title_id" class="card-title mb-2">{{ setting.title_id }}</h3>
        <div v-if="setting.desc_id" v-html="setting.desc_id"></div>
        <p v-else><i>No content yet</i></p>
      </div>
      <div class="col-md-6">
        <h4><b-badge variant="info">EN</b-badge></h4>
        <h3 v-if="setting.title_en" class="card-title mb-2">{{ setting.title_en }}</h3>
        <div v-if="setting.desc_en" v-html="setting.desc_en"></div>
        <p v-else><i>No content yet</i></p>
      </div>
    </div>
  </b-card-body>
  <b-card-body>
    <div class="row gutter-2">
      <div class="col-md-12">
        <h4 class="card-title mb-2">Company Values</h4>
      </div>
      <div class="col-md-4"
        v-for="key in Object.keys(setting.json_data||{})"
        :key="key"
      >
        <div class="main-card benefit-card">
          <div class="bullet-cta">
            <b-button
              class="btn-rounded btn-icon"
              variant="outline-warning"
              @click="doOpenModal(setting.json_data[key], key)"
              v-b-tooltip="'Edit Content'"
            >
              <i class="fas fa-pencil-alt"></i>
            </b-button>
          </div>
          <div class="main-card-body">
            <div class="benefit-card-media bg-white border">
              <img :src="parent.uploader(setting.json_data[key].img)" class="benefit-card-media__img img-responsive">
            </div>
            <h3 class="benefit-card__title">{{ setting.json_data[key].title_id }}</h3>
            <p class="benefit-card__desc">{{ setting.json_data[key].desc_id }}</p>
          </div>
        </div>
      </div>
    </div>
  </b-card-body>

  <b-modal
    id="modalNilai"
    :title="input.index !== false ? 'Update ' + 'Nilai Perusahaan' : 'Add New '+'Nilai Perusahaan'"
    @ok="doSubmitNilai"
    :busy="parent.loadingOverlay"
    ok-title="Save Changes"
    cancel-variant="secondary btn-rounded"
    ok-variant="primary btn-rounded"
  >
    <b-overlay
      :show="parent.loadingOverlay"
      rounded="sm"
    >
      <validation-observer
        v-slot="{ handleSubmit }" ref="VFormNilai"
      >
        <b-form @submit.prevent="handleSubmit(doSubmitNilai(false))">
          <div class="form-group">
            <label for="">Icon <strong class="text-danger">*</strong></label>
            <Uploader v-model="input.img" type="sejarah-icon"/>
            <VValidate 
              name="Icon" 
              v-model="input.img" 
              :rules="{required:1}"
            />
          </div>
          <div class="form-group">
            <label for="">Title (ID) <strong class="text-danger">*</strong></label>
            <input type="text" @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" class="form-control" v-model="input.title_id"/>
            <VValidate 
              name="Title (ID)" 
              v-model="input.title_id" 
              :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
            />
          </div>
          <div class="form-group">
            <label for="">Title (EN) <strong class="text-danger">*</strong></label>
            <input type="text" @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" class="form-control" v-model="input.title_en"/>
            <VValidate 
              name="Title (EN)" 
              v-model="input.title_en" 
              :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
            />
          </div>
          <div class="form-group">
            <label for="">Description (ID) <strong class="text-danger">*</strong></label>
            <textarea type="text" @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" class="form-control" v-model="input.desc_id" />
            <VValidate 
              name="Description (ID)" 
              v-model="input.desc_id" 
              :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
            />
          </div>
          <div class="form-group">
            <label for="">Description (EN) <strong class="text-danger">*</strong></label>
            <textarea type="text" @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" class="form-control" v-model="input.desc_en"/>
            <VValidate 
              name="Description (EN)" 
              v-model="input.desc_en" 
              :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
            />
          </div>
          <div class="form-group">
            <label for="">Alt Image (ID) <strong class="text-danger">*</strong></label>
            <input type="text" @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" class="form-control" v-model="input.alt_image_id"/>
            <VValidate 
              name="Description (EN)" 
              v-model="input.alt_image_id" 
              :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
            />
          </div>
          <div class="form-group">
            <label for="">Alt Image (EN) <strong class="text-danger">*</strong></label>
            <input type="text" @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" class="form-control" v-model="input.alt_image_en"/>
            <VValidate 
              name="Description (EN)" 
              v-model="input.alt_image_en" 
              :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
            />
          </div>
          <!--for submit -->
          <b-button type="submit" class="d-none"></b-button>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</b-card>
</template>

<script>
import Gen from '@/libs/Gen'
import CKEditor from '@/components/CKEditor'

export default{
  components:{ CKEditor },
  props:{ setting:Object },
  data(){
    return {
      input:{},
      openFormStatic:false,
    }
  },
  computed:{
    parent(){
      return this.$parent.$parent.$parent.$parent.$parent.$parent.$parent
    },
  },
  methods:{
    doOpenForm(){
      this.input = JSON.parse(JSON.stringify(this.setting))
      this.openFormStatic = true
    },
    doOpenModal(row, key){
      this.input = JSON.parse(JSON.stringify(row))
      this.input.key = key 
      this.$bvModal.show('modalNilai')
    },
    doSubmitContent(){
      this.$refs["VFormVisi"].validate().then(success => {
        if (!success) return
        let input = this.input
        input.needSubmit=false
        
        this.parent.loadingOverlay = true 
        const data = JSON.parse(JSON.stringify(input))

        Gen.apiRest(
          "/do/"+this.parent.modulePage, 
          {data: Object.assign({}, data, {
            type:'update-static-content', 
            id:input.id
          })}, 
          "POST"
        ).then(res=>{
            this.parent.loadingOverlay = false
            let resp = res.data

            if(Object.keys(resp.data.rowAlgoliaID).length){
              this.parent.submitStaicContentAlgolia('inv-company-info-visi-nilai',resp.data.rowAlgoliaID,'id')
            }
            if(Object.keys(resp.data.rowAlgoliaEN).length){
              this.parent.submitStaicContentAlgolia('inv-company-info-visi-nilai',resp.data.rowAlgoliaEN,'en')
            }
            this.$emit('update:setting', input)
            this.openFormStatic=false

            resp.statusType = 200
            this.parent.doSetAlertForm(resp)
        }).catch(err=>{
            this.parent.loadingOverlay = false
            
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.message = err.response?.data?.message
                err.messageError = err.message
            }

            this.parent.doSetAlertForm(err)
        })
      })
    },
    doSubmitNilai(bvModalEvt){
      if(bvModalEvt){
        bvModalEvt.preventDefault()
      }
      this.$refs['VFormNilai'].validate().then(success => {
        if (!success) return

        const input = this.input
        this.parent.loadingOverlay = true
        let SettingJSON = JSON.parse(JSON.stringify(this.setting.json_data))
        SettingJSON[input.key] = input

        Gen.apiRest(
          "/do/"+this.parent.modulePage, 
          {data: Object.assign({}, {
            type:'update-nilai', 
            data:SettingJSON
          })}, 
          "POST"
        ).then(res=>{
            this.parent.loadingOverlay = false
            let resp = res.data
            resp.statusType = 200
            this.parent.doSetAlertForm(resp)

            this.$set(this.setting, 'json_data', SettingJSON)
            this.$emit('update:setting', this.setting)
            this.$bvModal.hide('modalNilai')

        }).catch(err=>{
            this.parent.loadingOverlay = false
            
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.message = err.response?.data?.message
                err.messageError = err.message
            }

            this.parent.doSetAlertForm(err)
        })
      })
    },
    doSwicth(e){
      const changeStatus = e.target.checked?'Y':'N'
      this.$swal({
        title: `${changeStatus=='N' ? 'Hide this section' : 'Show this section'}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: changeStatus=="N"?'#f7ab38':'#30d64c',
        confirmButtonText:  `Yes, ${changeStatus=='N'? 'hide' : 'show'} it!`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.parent.loadingOverlay=true

          Gen.apiRest(
            "/do/"+this.parent.modulePage, 
            {data: Object.assign({}, {
              type:'update-onoff-static-content', 
              slug:this.setting.slug,
              status: changeStatus
            })}, 
            "POST"
          )
            .then(res=>{
              this.parent.loadingOverlay = false 
              this.setting.onOff = changeStatus

              let resp = res.data
                
              if(Object.keys(resp.data.rowAlgoliaID).length){
                this.parent.submitStaicContentAlgolia('inv-company-info-visi-nilai',resp.data.rowAlgoliaID,'id')
              }
              if(Object.keys(resp.data.rowAlgoliaEN).length){
                this.parent.submitStaicContentAlgolia('inv-company-info-visi-nilai',resp.data.rowAlgoliaEN,'en')
              }
              this.$swal({
                title: `Status changed to ${changeStatus=='Y'? 'Show' : 'Hide'}.`,
                icon: 'success',
              })
            })
        }else{
          this.setting.onOff = changeStatus=='Y'?'N':'Y'
        }
      })
    }
  },
}
</script>