<template>
<b-card no-body class="border">
  <template #header>
    <h5 class="card-title">Comissioners and Directors Affiliation</h5>
  </template>
  <b-card-body>
    <b-tabs pills>
      <b-tab v-for="(item, no) in setting" :key="no" :title="item.tab_name" :active="!no">
        <b-card-text>
          <validation-observer
            v-slot="{ handleSubmit }" :ref="'VFormHub'+no"
          >
            <b-form @submit.prevent="handleSubmit(doSubmitAfiliasi(key, item, 'VFormHub'+no))">
              <b-alert v-if="item.needSubmit && parent.moduleRole('manage_afiliasi')" variant="warning" show>
                <b>{{ parent.Config.wording.detectUpdate }}</b>
              </b-alert> 
              <b-row>
                <b-col lg="12">
                  <div class="text-center mt-4 table-responsive">
                    <table role="table" class="table table-striped table-bordered b-table">
                      <thead role="rowgroup" class="">
                        <tr role="row" class="table-info">
                          <th rowspan="3">Name</th>
                          <th colspan="9">{{item.type}} Relation with</th>
                          <th rowspan="3">Action</th>
                        </tr>
                        <tr role="row" class="table-info">
                          <th colspan="3">{{ item.opt_1 }}</th>
                          <th colspan="3">{{ item.opt_2 }}</th>
                          <th colspan="3">{{ item.opt_3 }}</th>
                        </tr>
                        <tr role="row" class="table-info">
                          <th role="columnheader" scope="col">Yes</th>
                          <th role="columnheader" scope="col">No</th>
                          <th role="columnheader" scope="col">Notes</th>
                          <th role="columnheader" scope="col">Yes</th>
                          <th role="columnheader" scope="col">No</th>
                          <th role="columnheader" scope="col">Notes</th>
                          <th role="columnheader" scope="col">Yes</th>
                          <th role="columnheader" scope="col">No</th>
                          <th role="columnheader" scope="col">Notes</th>
                        </tr>
                      </thead>
                      <tbody role="rowgroup" class="bg-white">
                        <template v-if="(item.data||[]).length">
                          <tr v-for="(v,k) in item.data" :key="no+'|'+k" role="row">
                            <td>
                              <b-form-group label-for="heroTitleId">
                                <b-form-input placeholder="e.g Markus Dinarto Pranoto" id="heroTitleId" v-model="v.name" @input="item['needSubmit']=true" />
                                <VValidate 
                                  :name="'Name-'+(no+1)+'.'+(k+1)" 
                                  v-model="v.name" 
                                  rules="required"
                                />
                              </b-form-group>
                            </td>
                            <td>
                              <b-form-radio class="l-20" v-model="v.opt_1_check" @input="item['needSubmit']=true" :name="'opt_1'+k" value="Y"></b-form-radio>
                            </td>
                            <td>
                              <b-form-radio class="l-20" v-model="v.opt_1_check" @input="item['needSubmit']=true" :name="'opt_1'+k" value="N"></b-form-radio>
                            </td>
                            <td>
                              <b-form-group label-for="heroTitleId">
                                <b-form-input placeholder="" id="heroTitleId" v-model="v.opt_1_note"  @input="item['needSubmit']=true"/>
                              </b-form-group>
                            </td>
                            <td>
                              <b-form-radio class="l-20" v-model="v.opt_2_check" @input="item['needSubmit']=true" :name="'opt_2'+k" value="Y"></b-form-radio>
                            </td>
                            <td>
                              <b-form-radio class="l-20" v-model="v.opt_2_check" @input="item['needSubmit']=true" :name="'opt_2'+k" value="N"></b-form-radio>
                            </td>
                            <td>
                              <b-form-group label-for="heroTitleId">
                                <b-form-input placeholder="" id="heroTitleId" v-model="v.opt_2_note" @input="item['needSubmit']=true" />
                              </b-form-group>
                            </td>
                            <td>
                              <b-form-radio class="l-20" v-model="v.opt_3_check" @input="item['needSubmit']=true" :name="'opt_3'+k" value="Y"></b-form-radio>
                            </td>
                            <td>
                              <b-form-radio class="l-20" v-model="v.opt_3_check" @input="item['needSubmit']=true" :name="'opt_3'+k" value="N"></b-form-radio>
                            </td>
                            <td>
                              <b-form-group label-for="heroTitleId">
                                <b-form-input placeholder="" id="heroTitleId" v-model="v.opt_3_note" @input="item['needSubmit']=true" />
                              </b-form-group>
                            </td>
                            <td>
                              <a 
                                @click="()=>{ 
                                  item.data.splice(k,1); 
                                  item['needSubmit']=true 
                                }" 
                                href="javascript:;" class="icon_action"
                              ><i class="ti-trash"></i></a>
                            </td>
                          </tr>
                        </template>
                        <template v-else>
                          <tr>
                            <th colspan="11">
                              <div class="text-center">No Data.</div>
                            </th>
                          </tr>
                        </template>
                        <tr>
                          <td colspan="11">
                            <div class="add_cta">
                              <p>
                                <a href="javascript:;" @click="()=>{
                                  item.data.push({
                                    opt_1_check : 'N',
                                    opt_2_check : 'N',
                                    opt_3_check : 'N'
                                  });
                                  item['needSubmit']=true
                                }"><i class="fas fa-plus"></i> Add New</a>
                                <button v-if="parent.moduleRole('manage_afiliasi')" type="submit" class="btn btn-info btn-rounded ml-2">Save Changes</button>
                              </p>
                            </div>
                          </td>
                        </tr>
                        
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-text>
      </b-tab>
    </b-tabs>
  </b-card-body>
</b-card>
</template>

<script>
import Gen from '@/libs/Gen'

export default{
  props:{ setting:[Array, Object] },
  data(){
    return {
    }
  },
  computed:{
    parent(){
      return this.$parent.$parent.$parent.$parent.$parent.$parent.$parent
    },
  },
  methods:{
    doSubmitAfiliasi(key, input, VForm){
      this.$refs[VForm][0].validate().then(success => {
        if (!success) return
        input.needSubmit=false
        
        this.parent.loadingOverlay = true 
        const data = JSON.parse(JSON.stringify(input.data))

        Gen.apiRest(
          "/do/"+this.parent.modulePage, 
          {data: Object.assign({}, {
            type:'manage-hub-afiliasi', 
            data:data,
            tab:input.tab
          })}, 
          "POST"
        ).then(res=>{
            this.parent.loadingOverlay = false
            let resp = res.data
            resp.statusType = 200
            this.parent.doSetAlertForm(resp)
            
            this.$set(this.setting, key, resp.data.row)
            this.$emit('update:setting', this.setting)
        }).catch(err=>{
            this.parent.loadingOverlay = false
            
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.message = err.response?.data?.message
                err.messageError = err.message
            }

            this.parent.doSetAlertForm(err)
        })
      })
    }
  },
}
</script>