<template>
<div>
  <b-card class="border">
    <template #header>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h5 class="card-title">Capital Market Supporting Institutions</h5>
        </div>
        <b-button v-if="parent.moduleRole('add_lembaga_pasar')" @click="openForm()" class="btn-rounded" variant="info">
          Add New Institution
          <i class="fa fa-plus-circle ml-2"></i>
        </b-button>
      </div>
    </template>
    
    <template v-if="!data">
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </template>
    <template v-else-if="(data||[]).length">
      <draggable v-if="parent.moduleRole('update_lembaga_pasar')" tag="div" class="row" v-model="data" @end="endDrag" :draggable="parent.moduleRole('update_lembaga_pasar') ? '.slide' :'.no-slide'">
        <b-col md=4
          v-for="(item,key) in data||[]"
          :key="key"
          class="mb-2 slide"
        >
          <div class="wrap_team">
            <figure class="thumb_wrap mb-5">
              <div class="bullet-cta">
                <b-button v-if="parent.moduleRole('update_lembaga_pasar')" @click="openForm(item, key)" class="btn-icon btn-rounded" variant="outline-info">
                  <i class="fas fa-pencil-alt"></i>
                </b-button>
                <b-button v-if="parent.moduleRole('update_status_lembaga_pasar')" @click="doChangeStatus(item)" class="btn-icon btn-rounded" variant="outline-warning">
                  <i class="fas fa-cog"></i>
                </b-button>
                <b-button v-if="parent.moduleRole('delete_lembaga_pasar')" @click="doDelete(item)" class="btn-icon btn-rounded" variant="outline-danger">
                  <i class="fas fa-trash-alt"></i>
                </b-button>
              </div>
            </figure>
            <div class="content_wrap_team">
              <h3>{{ item.name_id }}</h3>
              <p>{{ item.address }}</p>
              
              <span v-if="item.is_active=='Y'" class="label label-success">Active</span>
              <span v-else class="label label-danger">Inactive</span>
            </div>
          </div>
        </b-col>
      </draggable>
    </template>
    <template v-else>
      <h4 align="center">No Lembaga Pasar listed yet</h4>
    </template>

  </b-card>

  <b-modal
    id="modalLembaga"
    :title="input.index !== false ? 'Update ' + 'Lembaga' : 'Add New '+'Lembaga'"
    @ok="doSubmitLembaga"
    :busy="parent.loadingOverlay"
    cancel-variant="secondary btn-rounded"
    ok-variant="primary btn-rounded"
    ok-title="Save Changes"
  >
    <b-overlay
      :show="parent.loadingOverlay"
      rounded="sm"
    >
      <validation-observer
        v-slot="{ handleSubmit }" ref="VFormLembaga"
      >
        <b-form @submit.prevent="handleSubmit(doSubmitLembaga(false))">
          <div class="form-group">
            <label for="">Instituton Name (ID)<strong class="text-danger">*</strong></label>
            <input type="text"  @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" class="form-control" v-model="input.name_id" />
            <VValidate name="Instituton Name ID" v-model="input.name_id" :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}" />
          </div>
          <div class="form-group">
            <label for="">Instituton Name (EN)<strong class="text-danger">*</strong></label>
            <input type="text"  @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" class="form-control" v-model="input.name_en" />
            <VValidate name="Instituton Name EN" v-model="input.name_en" :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}" />
          </div>
          <div class="form-group">
            <label for="">Address <strong class="text-danger">*</strong></label>
            <b-textarea rows="7" @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" v-model="input.address"></b-textarea>
            <VValidate name="Alamat" v-model="input.address" :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}" />
          </div>
          <!--for submit -->
          <b-button type="submit" class="d-none"></b-button>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</div>
</template>
<script>
import Gen from '@/libs/Gen'
import draggable from 'vuedraggable'

export default{
  components:{
    draggable
  },
  props:{ data:[Array, Object, Boolean] },
  data(){
    return {
      perPage: 6,
      currentPage: 1,
      processPage:false,
      input:{},
    }
  },
  computed:{
    parent(){
      return this.$parent.$parent.$parent.$parent.$parent.$parent.$parent
    },
    parentChild(){
      return this.$parent.$parent.$parent.$parent.$parent
    },
    
  },
  methods:{
    endDrag() {
      Gen.apiRest(
        "/do/" + this.parent.modulePage, {
          data: {
            type: 'sort-lembaga',
            data: this.data
          }
        },
        "POST"
      )
    },
    openForm(row={}, index=false){
      this.input = JSON.parse(JSON.stringify(row))
      this.input.index = index 
      this.$bvModal.show('modalLembaga')
    },
    doSubmitLembaga(bvModalEvt){
      if(bvModalEvt){
        bvModalEvt.preventDefault()
      }
      this.$refs['VFormLembaga'].validate().then(success => {
        if (!success) return

        const input = this.input
        this.parent.loadingOverlay = true
        const data = JSON.parse(JSON.stringify(input))

        Gen.apiRest(
          "/do/"+this.parent.modulePage, 
          {data: Object.assign({}, data, {
            type:input.index===false?'add-lembaga':'update-lembaga', 
            id:input.id
          })}, 
          "POST"
        ).then(res=>{
            this.parent.loadingOverlay = false
            let resp = res.data
            resp.statusType = 200
            this.parent.doSetAlertForm(resp)

            let data = this.data
            if(input.index===false){
              data.push(resp.data.row)
              this.data.total = this.data.total+1
              this.parent.submitCRUDToAlgolia('lembaga_id_',resp.data.rowAlgoliaID, true)
              this.parent.submitCRUDToAlgolia('lembaga_en_',resp.data.rowAlgoliaEN, true)
            }else{
              this.$set(data, input.index, resp.data.row)
              this.parent.submitCRUDToAlgolia('lembaga_id_',resp.data.rowAlgoliaID, false, input.index)
              this.parent.submitCRUDToAlgolia('lembaga_en_',resp.data.rowAlgoliaEN, false, input.index)
            }
   
            this.$bvModal.hide('modalLembaga')

        }).catch(err=>{
            this.parent.loadingOverlay = false
            
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.message = err.response?.data?.message
                err.messageError = err.message
            }

            this.parent.doSetAlertForm(err)
        })
      })
    },
    doDelete(v){
      this.$swal({
        title: 'Delete this Capital Market Institution ?',        
        text: "You won't be able to restore this data!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.parent.loadingOverlay = true

          Gen.apiRest(
            "/do/"+this.parent.modulePage, 
            {data: Object.assign({}, {
              type:'delete-lembaga', 
              id:v.id
            })}, 
            "POST"
          )
            .then(()=>{
              this.parent.loadingOverlay = false 
              this.parentChild.restAPI('lembaga-pasar-data')
              this.parent.deleteFromAlgoliaList('lembaga_en_',v.id)
              this.parent.deleteFromAlgoliaList('lembaga_id_',v.id)
              this.$swal({
                title: 'Capital Market Institution Deleted Successfully!',
                icon: 'success',
              })
            })
        }
      })
    },
    doChangeStatus(v){
      let changeStatus = v.is_active == "Y" ? "N" : "Y"
      this.$swal({
        title: `Change status to ${changeStatus=='N' ? 'Inactive' : 'Active'}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText:  `Yes, change to ${changeStatus=='N'? 'Inactive' : 'Active'}!`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.parent.loadingOverlay=true

          Gen.apiRest(
            "/do/"+this.parent.modulePage, 
            {data: Object.assign({}, {
              type:'update-status-lembaga', 
              id:v.id,
              status: changeStatus
            })}, 
            "POST"
          )
            .then(res=>{
              this.parent.loadingOverlay = false 
              v.is_active = changeStatus

              let resp = res.data                            
              this.parent.submitCRUDToAlgolia('lembaga_id_',resp.data.rowAlgoliaID, false, v.id)
              this.parent.submitCRUDToAlgolia('lembaga_en_',resp.data.rowAlgoliaEN, false, v.id)

              this.$swal({
                title: `Status changed to ${changeStatus=='Y'? 'Active' : 'Inactive'}.`,
                icon: 'success',
              })
            })
        }
      })
    },
  },
  watch:{
    currentPage(v){
      this.processPage=true
      this.parentChild.restAPI('lembaga-pasar-data', {page:v}, ()=>{
        this.processPage=false
      })
    }
  }
}
</script>