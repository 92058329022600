<template>
  <b-container fluid>
    <bo-page-title />
    <section id="home">

      <b-tabs>
        <b-tab title="Page Settings">
          <HeroSection :setting.sync="heroSetting" />
          <b-card no-body>
            <b-tabs card pills>
              <b-tab title="About Company">
                <AboutSingkat :setting.sync="aboutSingkatStatic" />
                <SejarahSection :setting.sync="sejarahStatic" />
                <VisiNilaiSection :setting.sync="visiNilaiStatic" />
              </b-tab>
              <b-tab title="Company Associatives">
                <KomisarisSection :data.sync="komisarisData" />
                <DireksiSection :data.sync="direksiData" />
                <HubAfiliasi :setting.sync="hubAfiliasi" />
                <LembagaPasar :data.sync="lembagaPasarData" />
              </b-tab>
            </b-tabs>
          </b-card>

        </b-tab>
        <b-tab title="SEO Settings">
          <SeoSection :setting.sync="seoPageSetting" />
        </b-tab>
      </b-tabs>
    </section>
  </b-container>
</template>

<script>
import Gen from '@/libs/Gen'
import GlobalVue from '@/libs/Global.vue'

import HeroSection from './HeroSection.vue'
import SeoSection from './SeoSection.vue'
import AboutSingkat from './AboutSingkat.vue'
import SejarahSection from './SejarahSection.vue'
import VisiNilaiSection from './VisiNilaiSection.vue'
import KomisarisSection from './KomisarisSection.vue'
import DireksiSection from './DireksiSection.vue'
import HubAfiliasi from './HubAfiliasi.vue'
import LembagaPasar from './LembagaPasar.vue'
const _ = global._

export default {
  extends: GlobalVue,
  components: {
    HeroSection,
    SeoSection,
    AboutSingkat,
    SejarahSection,
    VisiNilaiSection,
    KomisarisSection,
    DireksiSection,
    HubAfiliasi,
    LembagaPasar,
  },
  data() {
    return {
      heroSetting:{},
      seoPageSetting:{},
      aboutSingkatStatic:{},
      sejarahStatic:{},
      visiNilaiStatic:{},
      hubAfiliasi:[],
      komisarisData:{data:false},
      direksiData:{data:false},
      lembagaPasarData:{data:false},
    }
  },
  mounted(){
    this.getAllData()
  },
  computed: {
  },
  methods: {
    getAllData(){
      this.loadingOverlay=true
      this.restAPI('page-setting')
      this.restAPI('static-content')
      this.restAPI('komisaris-data')
      this.restAPI('hub-afiliasi-setting')
      this.restAPI('direksi-data')
      this.restAPI('lembaga-pasar-data')
    },
    restAPI(slug, params=false, callback=false){
      Gen.apiRest(
        "/get/"+this.modulePage+"/"+slug,
        {
          params:params||{}
        }
      ).then(res=>{
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })
        this.loadingOverlay=false
        if(callback) callback()
      })
    },
  },
  watch:{
    $route(){
      this.getAllData()
    }
  }
}
</script>
